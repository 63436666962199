(function(){
	var raty = require('raty-js');
	var fetchReviews = Routing.generate('site_frontend_ajax_fetch_reviews');
	var targetReviews = Routing.generate('site_frontend_ajax_call_reviews');

	if ($('.js-review-box').length || $('.js-review-list').length){
		getReviews();
	}

	function getReviews(){
		$.ajax({
				url: fetchReviews,
				type: "GET",
				dataType: 'json',
				success: function (data) {
						if (data.status == 'succes') {
							if($('.js-review-box').length){
								createHomeReviews(data);
							} else {
								createReviews(data);
							}
						} else {

						}
				}
		});
	};

	function createHomeReviews(data){
		
		var box = $('.js-review-box');
		var counter = 0;
		data.reviews.map(function(review){
			if(counter < 10){
				var html = '<article class="home-review">';
						html += '<p class="home-review__body">' + review.Opmerkingen + '</p>';
						html += '<span class="home-review__score" data-score="'+ review.Score  +'"></span>';
						html += '<h2 class="home-review__author">'+ review.Naam  +'</h2>';
						html += '</article>';
				box.append(html);
				counter ++;
			}
		});

		$('.loading').remove();

		$('.js-btnrev').addClass('isLoaded');

		$('.home-review__score').raty({
			half: true,
			readOnly: true,
			size: 20,
			path: '/bundles/sitefrontend/img/rating',
			starHalf: 'star-half-alt.svg',
			starOff: 'star-off-alt.svg',
			starOn: 'star-on-alt.svg',
			score: function(){
				return $(this).attr('data-score') / 2;
			}
		});

		$('.js-review-slider').slick({
			autoplay: true,
			nextArrow: '<div class="review__arrow review__arrow--next"></div>',
			prevArrow: '<div class="review__arrow review__arrow--prev"></div>'
		});
	}

	function createReviews(data){

		var box = $('.js-review-list');

		data.reviews.map(function(review){
			var html = '<article class="review">';
					html += '<h2 class="review__author">'+ review.Naam  +'</h2>';
					html += '<span class="review__score" data-score="'+ review.Score  +'"></span>';
					html += '<p class="review__body">' + review.Opmerkingen + '</p>';
					html += '</article>';
			box.append(html);
		});

		$('.loading').remove();

		$('.js-btnrev').addClass('isLoaded');

		$('.review__score').raty({
			half: true,
			readOnly: true,
			size: 20,
			path: '/bundles/sitefrontend/img/rating',
			starHalf: 'star-half-alt.svg',
			starOff: 'star-off-alt.svg',
			starOn: 'star-on-alt.svg',
			score: function(){
				return $(this).attr('data-score') / 2;
			}
		});

		$('.js-review-slider').slick({
			autoplay: true
		});

		var average = 0;
		var total = 0;
		var score = 0;

		$('.review__score').each(function() {
				score += parseInt($(this).attr('data-score'));
				total++;
		});

		average = (Math.round(score / total)) / 2;

		var $average = '<div class="review__average" data-score="'+ average +'"></div>';
		var $total = '<div class="review__total">'+ total +' reviews</div>'
		
		$('.js-review-list').prepend($average, $total);

		$('.review__average').raty({
				path: '/bundles/sitefrontend/img/rating',
				starHalf: 'star-half-alt.svg',
				starOff: 'star-off-alt.svg',
				starOn: 'star-on-alt.svg',
				half: true,
				readOnly: true,
				width: false,
				score: function() {
						return $(this).attr('data-score');
				}
		});
	}

	$('.rating__userscore').raty({
			path: '/bundles/sitefrontend/img/rating',
			starHalf: 'star-half.svg',
			starOff: 'star-off.svg',
			starOn: 'star-on.svg',
			size: 20,
			width: false,
			half: false,
			hints: ['bad', 'poor', 'regular', 'good', 'amazing']
	});




			var txt = $('.rating__caption');
			var img = $('.rating__userscore img');
			var rate = $('.rating__userscore');

			var $bad;
			var $poor;
			var $regular;
			var $good;
			var $amazing;
			var $click;

			getLanguage();

			txt.text($click);

			img.hover(function() {
					var rating = $(this).attr('title');
					var ratingtxt = getRating(rating);
					txt.removeClass('placeholder');
					txt.text(ratingtxt);
			});

			img.click(function() {
					img.removeClass('selected');
					$(this).addClass('selected');
			});

			rate.mouseleave(function() {
					var rating = $('.selected').attr('title');
					var ratingtxt = getRating(rating);
					if (rating) {
							txt.text(ratingtxt);
					} else {
							txt.addClass('placeholder');
							txt.text($click);
					}
			});

			function getLanguage() {
					$lang = $('html').attr('lang');

					switch ($lang) {
							case 'nl':
									$bad = 'vreselijk';
									$poor = 'matig';
									$regular = 'gemiddeld';
									$good = 'heel goed';
									$amazing = 'uitstekend';
									$click = 'klik om te beoordelen';
									break;
							case 'fr':
									$bad = 'mal';
									$poor = 'pauvre';
									$regular = 'ordinaire';
									$good = 'bien';
									$amazing = 'magnifique';
									$click = 'cliquez pour évaluer';
									break;
							case 'de':
									$bad = 'schlecht';
									$poor = 'mäßig';
									$regular = 'durchschnitt';
									$good = 'gut';
									$amazing = 'ausgezeichnet';
									$click = 'klicken um zu bewerten';
									break;
							default:
									$bad = 'bad';
									$poor = 'poor';
									$regular = 'regular';
									$good = 'good';
									$amazing = 'amazing';
									$click = 'click to rate';
					}
			}

			function getRating(rating) {
					switch (rating) {
							case 'bad':
									ratingtxt = $bad;
									break;
							case 'poor':
									ratingtxt = $poor;
									break;
							case 'regular':
									ratingtxt = $regular;
									break;
							case 'good':
									ratingtxt = $good;
									break;
							case 'amazing':
									ratingtxt = $amazing;
									break;
							default:
									ratingtxt = '';
					}

					return ratingtxt;
			}


	$('#review__form').on('submit', function () {
		
		var form = $(this).serializeArray();

		var rating = form[0].value;
		var name = form[1].value;
		var email = form[2].value;
		var message = form[3].value;

		if(rating && name && email && message){
			$.ajax({
					url: targetReviews,
					data: {
							name: name,
							email: email,
							rating: rating,
							message: message
					},
					type: "POST",
					dataType: 'json',
					success: function (data) {
							if (data.status == 'succes') {
								$('#review__form')[0].reset();
								$('.rating__userscore').raty('score', 0);
								$("input[name='score']").val(0);
								img.removeClass('selected');
								$('.rating__caption').text($click);
								formSuccess();
							} else {
								formError();
							}
					}
			});
		} else {
			formError();
		}
		return false;
	});

	function formSuccess(){
		$('.form__message--success').addClass('isVisible');
		setTimeout(function(){
			$('.form__message--success').removeClass('isVisible');
		}, 5000)
		$('.form__message--error').removeClass('isVisible');
	};

	function formError(){
		$('.form__message--error').addClass('isVisible');
	};
})();
