(function($){
	var $hth = $('.header__top').outerHeight();
	var $hh = $('header').outerHeight();
	var q = Modernizr.mq('(min-width: 992px)');
	if(q){
		$(document).on('scroll', function(){
			if($(document).scrollTop() > $hth){
					$('header').addClass('isFixed');
					$('.header-placeholder').css('height', $hh);
			} else{
				$('header').removeClass('isFixed');
				$('.header-placeholder').css('height', 0);
			}
		});
	}
})(jQuery);
