(function(){
	var $btn = $('.js-scroll');
	if($btn.length){
	var $hh = $('.hero').outerHeight() + $('.hero').offset().top - 50;
		$btn.on('click', function(e){
			e.preventDefault();
			$('html, body').animate({
				scrollTop: $hh
			}, 500);
		});
	}
})();


(function(){
	var $btn = $('.js-scroll-up');
	if($btn.length){
		$btn.on('click', function(e){
			e.preventDefault();
			$('html, body').animate({
				scrollTop: 0
			}, 500);
		});
	}
})();
