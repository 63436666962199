var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyDP_-W678vG_pD9T4-dRbJ5tDvFPUq__AE';

(function() {
    if ($('#maps').length) {

        gmaps.load(function(google) {
            var el = document.getElementById('maps');
        var lat = 51.134493,
            long = 2.683250;
            var center = new google.maps.LatLng(lat, long);
            var icon = {
                   url: '/bundles/sitefrontend/img/pin.svg',
                scaledSize: new google.maps.Size(35, 35)
            };

            var options = {
                zoom: 17,
                center: center,
								scrollwheel: false,
								styles: [{
                    stylers: [{
                        saturation: -100
                    }]
                  }]
								};

            var map = new google.maps.Map(el, options);

            google.maps.event.addDomListener(window, "resize", function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

            var marker = new google.maps.Marker({
                position: center,
                map: map,
                icon: icon
            });
        });
    }
})();
